import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import * as types from '@/redux/actionTypes/player';
export var initialState = {};
var play = function play(state, action) {
  var nextState = _objectSpread({}, state);
  Object.keys(nextState).forEach(function (id) {
    nextState[id] = nextState[id] === 'PLAYING' ? 'PAUSED' : nextState[id];
  });
  return _objectSpread(_objectSpread({}, nextState), {}, _defineProperty({}, action.payload.id, 'PLAYING'));
};
export var reducerPlayer = function reducerPlayer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case types.PLAYER_ENDED:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.payload.id, 'ENDED'));
    case types.PLAYER_PAUSE:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.payload.id, 'PAUSED'));
    case types.PLAYER_PLAY:
      return play(state, action);
    default:
      return state;
  }
};
export default reducerPlayer;