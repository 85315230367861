import { produce } from 'immer';
import * as uiTypes from '@/redux/actionTypes/ui';
export var initialState = {
  scroll: {},
  reports: {
    filters: {
      opened: false
    },
    openedChart: null
  },
  isCompletedCoursesOpen: false,
  isSidebarOpen: false
};
var reducer = function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case uiTypes.OPEN_REPORTS_FILTERS:
      return produce(state, function (draft) {
        draft.reports.filters.opened = true;
      });
    case uiTypes.CLOSE_REPORTS_FILTERS:
      return produce(state, function (draft) {
        draft.reports.filters.opened = false;
      });
    case uiTypes.OPEN_REPORTS_CHART:
      return produce(state, function (draft) {
        draft.reports.openedChart = action.payload.chartId;
      });
    case uiTypes.CLOSE_REPORTS_CHART:
      return produce(state, function (draft) {
        draft.reports.openedChart = null;
      });
    case uiTypes.SET_IS_SIDEBAR_OPEN:
      return produce(state, function (draft) {
        draft.isSidebarOpen = action.payload.isSidebarOpen;
      });
    default:
      return state;
  }
};
export default reducer;